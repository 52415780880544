exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-tek-js": () => import("./../../../src/pages/about/AboutTek.js" /* webpackChunkName: "component---src-pages-about-about-tek-js" */),
  "component---src-pages-about-aboutmenu-js": () => import("./../../../src/pages/about/aboutmenu.js" /* webpackChunkName: "component---src-pages-about-aboutmenu-js" */),
  "component---src-pages-about-awardbadge-js": () => import("./../../../src/pages/about/awardbadge.js" /* webpackChunkName: "component---src-pages-about-awardbadge-js" */),
  "component---src-pages-about-banner-js": () => import("./../../../src/pages/about/banner.js" /* webpackChunkName: "component---src-pages-about-banner-js" */),
  "component---src-pages-about-blog-section-js": () => import("./../../../src/pages/about/blog_section.js" /* webpackChunkName: "component---src-pages-about-blog-section-js" */),
  "component---src-pages-about-impact-js": () => import("./../../../src/pages/about/impact.js" /* webpackChunkName: "component---src-pages-about-impact-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-mission-js": () => import("./../../../src/pages/about/mission.js" /* webpackChunkName: "component---src-pages-about-mission-js" */),
  "component---src-pages-about-story-js": () => import("./../../../src/pages/about/story.js" /* webpackChunkName: "component---src-pages-about-story-js" */),
  "component---src-pages-about-value-js": () => import("./../../../src/pages/about/value.js" /* webpackChunkName: "component---src-pages-about-value-js" */),
  "component---src-pages-about-vision-js": () => import("./../../../src/pages/about/vision.js" /* webpackChunkName: "component---src-pages-about-vision-js" */),
  "component---src-pages-blogdetails-bolgcontent-js": () => import("./../../../src/pages/blogdetails/bolgcontent.js" /* webpackChunkName: "component---src-pages-blogdetails-bolgcontent-js" */),
  "component---src-pages-blogdetails-index-js": () => import("./../../../src/pages/blogdetails/index.js" /* webpackChunkName: "component---src-pages-blogdetails-index-js" */),
  "component---src-pages-blogdetails-recentpost-js": () => import("./../../../src/pages/blogdetails/recentpost.js" /* webpackChunkName: "component---src-pages-blogdetails-recentpost-js" */),
  "component---src-pages-blogs-banner-js": () => import("./../../../src/pages/blogs/banner.js" /* webpackChunkName: "component---src-pages-blogs-banner-js" */),
  "component---src-pages-blogs-bloggrid-js": () => import("./../../../src/pages/blogs/bloggrid.js" /* webpackChunkName: "component---src-pages-blogs-bloggrid-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-careers-banner-js": () => import("./../../../src/pages/careers/banner.js" /* webpackChunkName: "component---src-pages-careers-banner-js" */),
  "component---src-pages-careers-careerlisting-js": () => import("./../../../src/pages/careers/careerlisting.js" /* webpackChunkName: "component---src-pages-careers-careerlisting-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-case-studies-banner-js": () => import("./../../../src/pages/case-studies/banner.js" /* webpackChunkName: "component---src-pages-case-studies-banner-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-profile-js": () => import("./../../../src/pages/case-studies/profile.js" /* webpackChunkName: "component---src-pages-case-studies-profile-js" */),
  "component---src-pages-casestudies-pizza-project-js": () => import("./../../../src/pages/casestudies/PizzaProject.js" /* webpackChunkName: "component---src-pages-casestudies-pizza-project-js" */),
  "component---src-pages-casestudies-self-project-js": () => import("./../../../src/pages/casestudies/SelfProject.js" /* webpackChunkName: "component---src-pages-casestudies-self-project-js" */),
  "component---src-pages-casestudies-tee-mates-project-js": () => import("./../../../src/pages/casestudies/TeeMatesProject.js" /* webpackChunkName: "component---src-pages-casestudies-tee-mates-project-js" */),
  "component---src-pages-casestudies-wellness-project-js": () => import("./../../../src/pages/casestudies/WellnessProject.js" /* webpackChunkName: "component---src-pages-casestudies-wellness-project-js" */),
  "component---src-pages-components-bannerfrom-js": () => import("./../../../src/pages/components/bannerfrom.js" /* webpackChunkName: "component---src-pages-components-bannerfrom-js" */),
  "component---src-pages-components-breadcrumbs-js": () => import("./../../../src/pages/components/breadcrumbs.js" /* webpackChunkName: "component---src-pages-components-breadcrumbs-js" */),
  "component---src-pages-components-featurebannner-js": () => import("./../../../src/pages/components/featurebannner.js" /* webpackChunkName: "component---src-pages-components-featurebannner-js" */),
  "component---src-pages-components-loader-js": () => import("./../../../src/pages/components/Loader.js" /* webpackChunkName: "component---src-pages-components-loader-js" */),
  "component---src-pages-components-tekrevolcareer-js": () => import("./../../../src/pages/components/tekrevolcareer.js" /* webpackChunkName: "component---src-pages-components-tekrevolcareer-js" */),
  "component---src-pages-contact-banner-js": () => import("./../../../src/pages/contact/banner.js" /* webpackChunkName: "component---src-pages-contact-banner-js" */),
  "component---src-pages-contact-contactfrom-js": () => import("./../../../src/pages/contact/contactfrom.js" /* webpackChunkName: "component---src-pages-contact-contactfrom-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-location-js": () => import("./../../../src/pages/contact/location.js" /* webpackChunkName: "component---src-pages-contact-location-js" */),
  "component---src-pages-home-awads-js": () => import("./../../../src/pages/home/awads.js" /* webpackChunkName: "component---src-pages-home-awads-js" */),
  "component---src-pages-home-awardsrecognitions-js": () => import("./../../../src/pages/home/awardsrecognitions.js" /* webpackChunkName: "component---src-pages-home-awardsrecognitions-js" */),
  "component---src-pages-home-banner-js": () => import("./../../../src/pages/home/banner.js" /* webpackChunkName: "component---src-pages-home-banner-js" */),
  "component---src-pages-home-casestudies-js": () => import("./../../../src/pages/home/casestudies.js" /* webpackChunkName: "component---src-pages-home-casestudies-js" */),
  "component---src-pages-home-expertise-js": () => import("./../../../src/pages/home/expertise.js" /* webpackChunkName: "component---src-pages-home-expertise-js" */),
  "component---src-pages-home-featured-js": () => import("./../../../src/pages/home/featured.js" /* webpackChunkName: "component---src-pages-home-featured-js" */),
  "component---src-pages-home-insights-js": () => import("./../../../src/pages/home/insights.js" /* webpackChunkName: "component---src-pages-home-insights-js" */),
  "component---src-pages-home-marqeebanner-js": () => import("./../../../src/pages/home/marqeebanner.js" /* webpackChunkName: "component---src-pages-home-marqeebanner-js" */),
  "component---src-pages-home-partner-js": () => import("./../../../src/pages/home/partner.js" /* webpackChunkName: "component---src-pages-home-partner-js" */),
  "component---src-pages-home-serviceindustries-js": () => import("./../../../src/pages/home/serviceindustries.js" /* webpackChunkName: "component---src-pages-home-serviceindustries-js" */),
  "component---src-pages-home-servicesboxes-js": () => import("./../../../src/pages/home/servicesboxes.js" /* webpackChunkName: "component---src-pages-home-servicesboxes-js" */),
  "component---src-pages-home-serviceslinks-js": () => import("./../../../src/pages/home/serviceslinks.js" /* webpackChunkName: "component---src-pages-home-serviceslinks-js" */),
  "component---src-pages-home-tekrevoljourney-js": () => import("./../../../src/pages/home/tekrevoljourney.js" /* webpackChunkName: "component---src-pages-home-tekrevoljourney-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-banner-js": () => import("./../../../src/pages/industries/banner.js" /* webpackChunkName: "component---src-pages-industries-banner-js" */),
  "component---src-pages-industries-demandfeatures-js": () => import("./../../../src/pages/industries/demandfeatures.js" /* webpackChunkName: "component---src-pages-industries-demandfeatures-js" */),
  "component---src-pages-industries-developmentprocess-js": () => import("./../../../src/pages/industries/developmentprocess.js" /* webpackChunkName: "component---src-pages-industries-developmentprocess-js" */),
  "component---src-pages-industries-globalmarket-js": () => import("./../../../src/pages/industries/globalmarket.js" /* webpackChunkName: "component---src-pages-industries-globalmarket-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-leadingdemand-js": () => import("./../../../src/pages/industries/leadingdemand.js" /* webpackChunkName: "component---src-pages-industries-leadingdemand-js" */),
  "component---src-pages-industries-question-js": () => import("./../../../src/pages/industries/question.js" /* webpackChunkName: "component---src-pages-industries-question-js" */),
  "component---src-pages-industries-revolutionindustries-js": () => import("./../../../src/pages/industries/revolutionindustries.js" /* webpackChunkName: "component---src-pages-industries-revolutionindustries-js" */),
  "component---src-pages-industries-solutionled-js": () => import("./../../../src/pages/industries/solutionled.js" /* webpackChunkName: "component---src-pages-industries-solutionled-js" */),
  "component---src-pages-language-banner-js": () => import("./../../../src/pages/language/banner.js" /* webpackChunkName: "component---src-pages-language-banner-js" */),
  "component---src-pages-language-developmentprocess-js": () => import("./../../../src/pages/language/developmentprocess.js" /* webpackChunkName: "component---src-pages-language-developmentprocess-js" */),
  "component---src-pages-language-hiredeveloper-js": () => import("./../../../src/pages/language/hiredeveloper.js" /* webpackChunkName: "component---src-pages-language-hiredeveloper-js" */),
  "component---src-pages-language-index-js": () => import("./../../../src/pages/language/index.js" /* webpackChunkName: "component---src-pages-language-index-js" */),
  "component---src-pages-language-languagedevelopment-js": () => import("./../../../src/pages/language/languagedevelopment.js" /* webpackChunkName: "component---src-pages-language-languagedevelopment-js" */),
  "component---src-pages-layout-casestudy-casestudycategory-js": () => import("./../../../src/pages/layout/casestudy/casestudycategory.js" /* webpackChunkName: "component---src-pages-layout-casestudy-casestudycategory-js" */),
  "component---src-pages-layout-casestudy-casestudylist-js": () => import("./../../../src/pages/layout/casestudy/casestudylist.js" /* webpackChunkName: "component---src-pages-layout-casestudy-casestudylist-js" */),
  "component---src-pages-layout-casestudy-casestudysidemenu-js": () => import("./../../../src/pages/layout/casestudy/casestudysidemenu.js" /* webpackChunkName: "component---src-pages-layout-casestudy-casestudysidemenu-js" */),
  "component---src-pages-layout-footer-js": () => import("./../../../src/pages/layout/Footer.js" /* webpackChunkName: "component---src-pages-layout-footer-js" */),
  "component---src-pages-layout-header-js": () => import("./../../../src/pages/layout/Header.js" /* webpackChunkName: "component---src-pages-layout-header-js" */),
  "component---src-pages-layout-industries-industriescategory-js": () => import("./../../../src/pages/layout/industries/industriescategory.js" /* webpackChunkName: "component---src-pages-layout-industries-industriescategory-js" */),
  "component---src-pages-layout-industries-industrieslist-js": () => import("./../../../src/pages/layout/industries/industrieslist.js" /* webpackChunkName: "component---src-pages-layout-industries-industrieslist-js" */),
  "component---src-pages-layout-industries-industriessidemenu-js": () => import("./../../../src/pages/layout/industries/industriessidemenu.js" /* webpackChunkName: "component---src-pages-layout-industries-industriessidemenu-js" */),
  "component---src-pages-layout-languages-languagescategory-js": () => import("./../../../src/pages/layout/languages/languagescategory.js" /* webpackChunkName: "component---src-pages-layout-languages-languagescategory-js" */),
  "component---src-pages-layout-languages-languageslist-js": () => import("./../../../src/pages/layout/languages/languageslist.js" /* webpackChunkName: "component---src-pages-layout-languages-languageslist-js" */),
  "component---src-pages-layout-languages-languagessidemenu-js": () => import("./../../../src/pages/layout/languages/languagessidemenu.js" /* webpackChunkName: "component---src-pages-layout-languages-languagessidemenu-js" */),
  "component---src-pages-layout-layout-js": () => import("./../../../src/pages/layout/layout.js" /* webpackChunkName: "component---src-pages-layout-layout-js" */),
  "component---src-pages-layout-location-locationcategory-js": () => import("./../../../src/pages/layout/location/locationcategory.js" /* webpackChunkName: "component---src-pages-layout-location-locationcategory-js" */),
  "component---src-pages-layout-location-locationlist-js": () => import("./../../../src/pages/layout/location/locationlist.js" /* webpackChunkName: "component---src-pages-layout-location-locationlist-js" */),
  "component---src-pages-layout-location-locationsidemenu-js": () => import("./../../../src/pages/layout/location/locationsidemenu.js" /* webpackChunkName: "component---src-pages-layout-location-locationsidemenu-js" */),
  "component---src-pages-layout-locationgcc-locationgcccategory-js": () => import("./../../../src/pages/layout/locationgcc/locationgcccategory.js" /* webpackChunkName: "component---src-pages-layout-locationgcc-locationgcccategory-js" */),
  "component---src-pages-layout-locationgcc-locationgcclist-js": () => import("./../../../src/pages/layout/locationgcc/locationgcclist.js" /* webpackChunkName: "component---src-pages-layout-locationgcc-locationgcclist-js" */),
  "component---src-pages-layout-locationgcc-locationgccsidemenu-js": () => import("./../../../src/pages/layout/locationgcc/locationgccsidemenu.js" /* webpackChunkName: "component---src-pages-layout-locationgcc-locationgccsidemenu-js" */),
  "component---src-pages-layout-platforms-platformscategory-js": () => import("./../../../src/pages/layout/platforms/platformscategory.js" /* webpackChunkName: "component---src-pages-layout-platforms-platformscategory-js" */),
  "component---src-pages-layout-platforms-platformslist-js": () => import("./../../../src/pages/layout/platforms/platformslist.js" /* webpackChunkName: "component---src-pages-layout-platforms-platformslist-js" */),
  "component---src-pages-layout-platforms-platformssidemenu-js": () => import("./../../../src/pages/layout/platforms/platformssidemenu.js" /* webpackChunkName: "component---src-pages-layout-platforms-platformssidemenu-js" */),
  "component---src-pages-layout-seo-js": () => import("./../../../src/pages/layout/seo.js" /* webpackChunkName: "component---src-pages-layout-seo-js" */),
  "component---src-pages-layout-service-servicescategory-js": () => import("./../../../src/pages/layout/service/servicescategory.js" /* webpackChunkName: "component---src-pages-layout-service-servicescategory-js" */),
  "component---src-pages-layout-service-serviceslist-js": () => import("./../../../src/pages/layout/service/serviceslist.js" /* webpackChunkName: "component---src-pages-layout-service-serviceslist-js" */),
  "component---src-pages-layout-service-servicessidemenu-js": () => import("./../../../src/pages/layout/service/servicessidemenu.js" /* webpackChunkName: "component---src-pages-layout-service-servicessidemenu-js" */),
  "component---src-pages-layout-solutions-solutionscategory-js": () => import("./../../../src/pages/layout/solutions/solutionscategory.js" /* webpackChunkName: "component---src-pages-layout-solutions-solutionscategory-js" */),
  "component---src-pages-layout-solutions-solutionsidemenu-js": () => import("./../../../src/pages/layout/solutions/solutionsidemenu.js" /* webpackChunkName: "component---src-pages-layout-solutions-solutionsidemenu-js" */),
  "component---src-pages-layout-solutions-solutionslist-js": () => import("./../../../src/pages/layout/solutions/solutionslist.js" /* webpackChunkName: "component---src-pages-layout-solutions-solutionslist-js" */),
  "component---src-pages-lifeattekrevol-banner-js": () => import("./../../../src/pages/lifeattekrevol/banner.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-banner-js" */),
  "component---src-pages-lifeattekrevol-blog-section-js": () => import("./../../../src/pages/lifeattekrevol/blog_section.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-blog-section-js" */),
  "component---src-pages-lifeattekrevol-index-js": () => import("./../../../src/pages/lifeattekrevol/index.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-index-js" */),
  "component---src-pages-lifeattekrevol-learners-tekrevol-js": () => import("./../../../src/pages/lifeattekrevol/learners_tekrevol.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-learners-tekrevol-js" */),
  "component---src-pages-lifeattekrevol-longlife-js": () => import("./../../../src/pages/lifeattekrevol/longlife.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-longlife-js" */),
  "component---src-pages-lifeattekrevol-slider-tekrevol-js": () => import("./../../../src/pages/lifeattekrevol/slider_tekrevol.js" /* webpackChunkName: "component---src-pages-lifeattekrevol-slider-tekrevol-js" */),
  "component---src-pages-loaction-page-index-js": () => import("./../../../src/pages/loaction-page/index.js" /* webpackChunkName: "component---src-pages-loaction-page-index-js" */),
  "component---src-pages-loaction-page-locationlisting-js": () => import("./../../../src/pages/loaction-page/locationlisting.js" /* webpackChunkName: "component---src-pages-loaction-page-locationlisting-js" */),
  "component---src-pages-location-us-banner-js": () => import("./../../../src/pages/location/us/banner.js" /* webpackChunkName: "component---src-pages-location-us-banner-js" */),
  "component---src-pages-location-us-expert-developer-js": () => import("./../../../src/pages/location/us/expert_developer.js" /* webpackChunkName: "component---src-pages-location-us-expert-developer-js" */),
  "component---src-pages-location-us-expert-software-js": () => import("./../../../src/pages/location/us/expert_software.js" /* webpackChunkName: "component---src-pages-location-us-expert-software-js" */),
  "component---src-pages-location-us-index-js": () => import("./../../../src/pages/location/us/index.js" /* webpackChunkName: "component---src-pages-location-us-index-js" */),
  "component---src-pages-location-us-leading-services-js": () => import("./../../../src/pages/location/us/leading_services.js" /* webpackChunkName: "component---src-pages-location-us-leading-services-js" */),
  "component---src-pages-location-us-leadingsoftware-js": () => import("./../../../src/pages/location/us/leadingsoftware.js" /* webpackChunkName: "component---src-pages-location-us-leadingsoftware-js" */),
  "component---src-pages-location-us-locationpath-js": () => import("./../../../src/pages/location/us/locationpath.js" /* webpackChunkName: "component---src-pages-location-us-locationpath-js" */),
  "component---src-pages-location-us-permier-tech-js": () => import("./../../../src/pages/location/us/permier_tech.js" /* webpackChunkName: "component---src-pages-location-us-permier-tech-js" */),
  "component---src-pages-locationgcc-gcc-banner-js": () => import("./../../../src/pages/locationgcc/gcc/banner.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-banner-js" */),
  "component---src-pages-locationgcc-gcc-custom-solution-js": () => import("./../../../src/pages/locationgcc/gcc/custom_solution.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-custom-solution-js" */),
  "component---src-pages-locationgcc-gcc-developmentprocess-js": () => import("./../../../src/pages/locationgcc/gcc/developmentprocess.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-developmentprocess-js" */),
  "component---src-pages-locationgcc-gcc-digital-solution-js": () => import("./../../../src/pages/locationgcc/gcc/digital_solution.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-digital-solution-js" */),
  "component---src-pages-locationgcc-gcc-discovers-js": () => import("./../../../src/pages/locationgcc/gcc/discovers.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-discovers-js" */),
  "component---src-pages-locationgcc-gcc-expert-developer-js": () => import("./../../../src/pages/locationgcc/gcc/expert_developer.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-expert-developer-js" */),
  "component---src-pages-locationgcc-gcc-growth-plane-js": () => import("./../../../src/pages/locationgcc/gcc/growth_plane.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-growth-plane-js" */),
  "component---src-pages-locationgcc-gcc-index-js": () => import("./../../../src/pages/locationgcc/gcc/index.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-index-js" */),
  "component---src-pages-locationgcc-gcc-location-pin-js": () => import("./../../../src/pages/locationgcc/gcc/location_pin.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-location-pin-js" */),
  "component---src-pages-locationgcc-gcc-toprated-js": () => import("./../../../src/pages/locationgcc/gcc/toprated.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-toprated-js" */),
  "component---src-pages-locationgcc-gcc-workwith-js": () => import("./../../../src/pages/locationgcc/gcc/workwith.js" /* webpackChunkName: "component---src-pages-locationgcc-gcc-workwith-js" */),
  "component---src-pages-platforms-amazonservices-js": () => import("./../../../src/pages/platforms/amazonservices.js" /* webpackChunkName: "component---src-pages-platforms-amazonservices-js" */),
  "component---src-pages-platforms-banner-js": () => import("./../../../src/pages/platforms/banner.js" /* webpackChunkName: "component---src-pages-platforms-banner-js" */),
  "component---src-pages-platforms-consulting-js": () => import("./../../../src/pages/platforms/consulting.js" /* webpackChunkName: "component---src-pages-platforms-consulting-js" */),
  "component---src-pages-platforms-future-js": () => import("./../../../src/pages/platforms/future.js" /* webpackChunkName: "component---src-pages-platforms-future-js" */),
  "component---src-pages-platforms-harness-js": () => import("./../../../src/pages/platforms/harness.js" /* webpackChunkName: "component---src-pages-platforms-harness-js" */),
  "component---src-pages-platforms-index-js": () => import("./../../../src/pages/platforms/index.js" /* webpackChunkName: "component---src-pages-platforms-index-js" */),
  "component---src-pages-platforms-readybuild-js": () => import("./../../../src/pages/platforms/readybuild.js" /* webpackChunkName: "component---src-pages-platforms-readybuild-js" */),
  "component---src-pages-platforms-whychoose-js": () => import("./../../../src/pages/platforms/whychoose.js" /* webpackChunkName: "component---src-pages-platforms-whychoose-js" */),
  "component---src-pages-podcast-banner-js": () => import("./../../../src/pages/podcast/banner.js" /* webpackChunkName: "component---src-pages-podcast-banner-js" */),
  "component---src-pages-podcast-index-js": () => import("./../../../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-pages-podcast-newsletter-js": () => import("./../../../src/pages/podcast/newsletter.js" /* webpackChunkName: "component---src-pages-podcast-newsletter-js" */),
  "component---src-pages-podcast-podcast-section-js": () => import("./../../../src/pages/podcast/podcast_section.js" /* webpackChunkName: "component---src-pages-podcast-podcast-section-js" */),
  "component---src-pages-podcast-podcast-story-js": () => import("./../../../src/pages/podcast/podcast_story.js" /* webpackChunkName: "component---src-pages-podcast-podcast-story-js" */),
  "component---src-pages-podcast-podcastbg-js": () => import("./../../../src/pages/podcast/podcastbg.js" /* webpackChunkName: "component---src-pages-podcast-podcastbg-js" */),
  "component---src-pages-podcast-podcastlisting-js": () => import("./../../../src/pages/podcast/podcastlisting.js" /* webpackChunkName: "component---src-pages-podcast-podcastlisting-js" */),
  "component---src-pages-podcastdetail-banner-js": () => import("./../../../src/pages/podcastdetail/banner.js" /* webpackChunkName: "component---src-pages-podcastdetail-banner-js" */),
  "component---src-pages-podcastdetail-episodesection-js": () => import("./../../../src/pages/podcastdetail/episodesection.js" /* webpackChunkName: "component---src-pages-podcastdetail-episodesection-js" */),
  "component---src-pages-podcastdetail-index-js": () => import("./../../../src/pages/podcastdetail/index.js" /* webpackChunkName: "component---src-pages-podcastdetail-index-js" */),
  "component---src-pages-podcastdetail-mention-js": () => import("./../../../src/pages/podcastdetail/mention.js" /* webpackChunkName: "component---src-pages-podcastdetail-mention-js" */),
  "component---src-pages-podcastdetail-timestamp-js": () => import("./../../../src/pages/podcastdetail/timestamp.js" /* webpackChunkName: "component---src-pages-podcastdetail-timestamp-js" */),
  "component---src-pages-press-banner-js": () => import("./../../../src/pages/press/banner.js" /* webpackChunkName: "component---src-pages-press-banner-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-press-innerbanner-js": () => import("./../../../src/pages/press/innerbanner.js" /* webpackChunkName: "component---src-pages-press-innerbanner-js" */),
  "component---src-pages-press-pressgrid-js": () => import("./../../../src/pages/press/pressgrid.js" /* webpackChunkName: "component---src-pages-press-pressgrid-js" */),
  "component---src-pages-pressdetails-banner-js": () => import("./../../../src/pages/pressdetails/banner.js" /* webpackChunkName: "component---src-pages-pressdetails-banner-js" */),
  "component---src-pages-pressdetails-index-js": () => import("./../../../src/pages/pressdetails/index.js" /* webpackChunkName: "component---src-pages-pressdetails-index-js" */),
  "component---src-pages-pressdetails-presscontent-js": () => import("./../../../src/pages/pressdetails/presscontent.js" /* webpackChunkName: "component---src-pages-pressdetails-presscontent-js" */),
  "component---src-pages-pressdetails-recentpress-js": () => import("./../../../src/pages/pressdetails/recentpress.js" /* webpackChunkName: "component---src-pages-pressdetails-recentpress-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-profile-banner-js": () => import("./../../../src/pages/profile/banner.js" /* webpackChunkName: "component---src-pages-profile-banner-js" */),
  "component---src-pages-profile-breadcrumbs-js": () => import("./../../../src/pages/profile/breadcrumbs.js" /* webpackChunkName: "component---src-pages-profile-breadcrumbs-js" */),
  "component---src-pages-profile-challenges-js": () => import("./../../../src/pages/profile/challenges.js" /* webpackChunkName: "component---src-pages-profile-challenges-js" */),
  "component---src-pages-profile-concept-js": () => import("./../../../src/pages/profile/concept.js" /* webpackChunkName: "component---src-pages-profile-concept-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-profile-outcomes-js": () => import("./../../../src/pages/profile/outcomes.js" /* webpackChunkName: "component---src-pages-profile-outcomes-js" */),
  "component---src-pages-profile-paltform-js": () => import("./../../../src/pages/profile/paltform.js" /* webpackChunkName: "component---src-pages-profile-paltform-js" */),
  "component---src-pages-profile-projectlog-js": () => import("./../../../src/pages/profile/projectlog.js" /* webpackChunkName: "component---src-pages-profile-projectlog-js" */),
  "component---src-pages-profile-result-js": () => import("./../../../src/pages/profile/result.js" /* webpackChunkName: "component---src-pages-profile-result-js" */),
  "component---src-pages-profile-techstack-js": () => import("./../../../src/pages/profile/techstack.js" /* webpackChunkName: "component---src-pages-profile-techstack-js" */),
  "component---src-pages-services-agile-js": () => import("./../../../src/pages/services/agile.js" /* webpackChunkName: "component---src-pages-services-agile-js" */),
  "component---src-pages-services-appbuild-js": () => import("./../../../src/pages/services/appbuild.js" /* webpackChunkName: "component---src-pages-services-appbuild-js" */),
  "component---src-pages-services-appcost-js": () => import("./../../../src/pages/services/appcost.js" /* webpackChunkName: "component---src-pages-services-appcost-js" */),
  "component---src-pages-services-banner-js": () => import("./../../../src/pages/services/banner.js" /* webpackChunkName: "component---src-pages-services-banner-js" */),
  "component---src-pages-services-buildpurpose-js": () => import("./../../../src/pages/services/buildpurpose.js" /* webpackChunkName: "component---src-pages-services-buildpurpose-js" */),
  "component---src-pages-services-developmentprocess-js": () => import("./../../../src/pages/services/developmentprocess.js" /* webpackChunkName: "component---src-pages-services-developmentprocess-js" */),
  "component---src-pages-services-developmenttechstack-js": () => import("./../../../src/pages/services/developmenttechstack.js" /* webpackChunkName: "component---src-pages-services-developmenttechstack-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-industries-js": () => import("./../../../src/pages/services/Industries.js" /* webpackChunkName: "component---src-pages-services-industries-js" */),
  "component---src-pages-services-page-banner-js": () => import("./../../../src/pages/services-page/banner.js" /* webpackChunkName: "component---src-pages-services-page-banner-js" */),
  "component---src-pages-services-page-index-js": () => import("./../../../src/pages/services-page/index.js" /* webpackChunkName: "component---src-pages-services-page-index-js" */),
  "component---src-pages-services-page-servicelisting-js": () => import("./../../../src/pages/services-page/servicelisting.js" /* webpackChunkName: "component---src-pages-services-page-servicelisting-js" */),
  "component---src-pages-services-productlifecycle-js": () => import("./../../../src/pages/services/productlifecycle.js" /* webpackChunkName: "component---src-pages-services-productlifecycle-js" */),
  "component---src-pages-services-question-js": () => import("./../../../src/pages/services/question.js" /* webpackChunkName: "component---src-pages-services-question-js" */),
  "component---src-pages-services-servicespartner-js": () => import("./../../../src/pages/services/servicespartner.js" /* webpackChunkName: "component---src-pages-services-servicespartner-js" */),
  "component---src-pages-services-solution-js": () => import("./../../../src/pages/services/solution.js" /* webpackChunkName: "component---src-pages-services-solution-js" */),
  "component---src-pages-services-technologysec-js": () => import("./../../../src/pages/services/technologysec.js" /* webpackChunkName: "component---src-pages-services-technologysec-js" */),
  "component---src-pages-solution-banner-js": () => import("./../../../src/pages/solution/banner.js" /* webpackChunkName: "component---src-pages-solution-banner-js" */),
  "component---src-pages-solution-contentsection-js": () => import("./../../../src/pages/solution/contentsection.js" /* webpackChunkName: "component---src-pages-solution-contentsection-js" */),
  "component---src-pages-solution-index-js": () => import("./../../../src/pages/solution/index.js" /* webpackChunkName: "component---src-pages-solution-index-js" */),
  "component---src-pages-solution-notebox-js": () => import("./../../../src/pages/solution/notebox.js" /* webpackChunkName: "component---src-pages-solution-notebox-js" */),
  "component---src-pages-solution-ourfocus-js": () => import("./../../../src/pages/solution/ourfocus.js" /* webpackChunkName: "component---src-pages-solution-ourfocus-js" */),
  "component---src-pages-solution-ourpractice-js": () => import("./../../../src/pages/solution/ourpractice.js" /* webpackChunkName: "component---src-pages-solution-ourpractice-js" */),
  "component---src-pages-solution-page-index-js": () => import("./../../../src/pages/solution-page/index.js" /* webpackChunkName: "component---src-pages-solution-page-index-js" */),
  "component---src-pages-solution-page-solutoinlisting-js": () => import("./../../../src/pages/solution-page/solutoinlisting.js" /* webpackChunkName: "component---src-pages-solution-page-solutoinlisting-js" */),
  "component---src-pages-solution-techdevelopment-js": () => import("./../../../src/pages/solution/techdevelopment.js" /* webpackChunkName: "component---src-pages-solution-techdevelopment-js" */),
  "component---src-pages-solution-technology-js": () => import("./../../../src/pages/solution/technology.js" /* webpackChunkName: "component---src-pages-solution-technology-js" */),
  "component---src-pages-solution-techsolution-js": () => import("./../../../src/pages/solution/techsolution.js" /* webpackChunkName: "component---src-pages-solution-techsolution-js" */),
  "component---src-pages-technology-page-index-js": () => import("./../../../src/pages/technology-page/index.js" /* webpackChunkName: "component---src-pages-technology-page-index-js" */),
  "component---src-pages-technology-page-technoloylisting-js": () => import("./../../../src/pages/technology-page/technoloylisting.js" /* webpackChunkName: "component---src-pages-technology-page-technoloylisting-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */)
}

